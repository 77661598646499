@media (min-width: 601px){
  div.Container{
    justify-self: right;
    flex-flow: column nowrap;
    flex: 1;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainWhite);
    position: relative;
  
    &:last-of-type {
      margin-bottom: 0px;
    }
  
    div.TopDiv {
      /* text-align: center; */
      display: flex;
      flex: 1;
      min-height: 140px;
      flex-flow: row wrap;
      justify-content: space-between;
      color: black;
      padding: 5px;
  
      div.DivLeft {
        flex: 0 0 180px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        align-content: center;
        padding: 5px;
  
        div.DivLeftUp {
          /* border: 1px solid red; */
          color: var(--mainColor);
          display: flex;
          justify-content: flex-end;
  
          .DescOuterDiv {
            position: relative;
            height: 30px;
            /* width: 100px; */
            text-align: left;
            cursor: help;
  
            .label:hover{
              opacity: .6;
            }
            
            .DescOuterPopup {
              position: fixed;
              max-width: 500px;
              z-index: 1;
  
              .DescArrow {
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                border: 1px solid var(--black);
                position: absolute;
                left: -7px;
                top: 24px;
                z-index: 1;
              }
  
              .DescArrow2 {
                height: 13px;
                width: 13px;
                transform: rotate(45deg);
                position: absolute;
                left: -6px;
                top: 25px;
                z-index: 3;
                background-color: var(--mainWhite);
              }
  
              .DescOuterPopupIn {
                border: 1px solid var(--black);
                display: inline-block;
                padding: 10px;
                padding-left: 15px;
                padding-right: 15px;
                background-color: var(--mainWhite);
                text-align: right;
                position: sticky;
                z-index: 2;
                color: var(--black);
                border-radius: var(--brdRadius);
  
                .DescPopupIcon {
                  width: 100%;
                  height: 30px;
                }
  
                .DescPopupText {
                  white-space: pre-wrap;
                }
              }
            }
          }
        }
        
        div.DivLeftUp > * + * {
          margin-right: 20px;
        }

        div.DivLeftBottom {
          display: flex;
          flex-flow: row nowrap;
          width: 100%;
          align-content: flex-end;
          justify-content: center;
          border-radius: 50px;
          border-width: 0px;
          height: 45px;
          position: relative;
  
          div.BtnLeftButton {
            align-self: center;
            justify-self: center;
            white-space: nowrap;
            padding: 5px 0 5px 0;
            font-size: 24px;
            color: var(--mainWhite);
            border-radius: 50px;
            border-width: 0px;
            margin-left: 15px;
          }
          div.BtnLeftButton:hover{
            cursor: pointer;
          }
          
          div.PlusLeftButton {
            margin-top: 10px;
            margin-left: 5px;
            width: 25px;
            height: 25px;
            color: white;
            line-height: 25px;
            font-size: 43px;
            font-weight: 300;
            color: var(--mainWhite);
          }
          
          div.PlusLeftButton:hover{
            cursor: pointer;
          }
  
          div.PlusButton{
            position: absolute;
            margin-top: 10px;
            margin-right: 20px;
            right: 10px;
            color: white;
            line-height: 25px;
            font-size: 33px;
            font-weight: 300;
            color: var(--mainWhite);
          }
  
          div.ItemAmount{
            /* position: absolute; */
            margin-top: 10px;
            align: center;
            color: white;
            line-height: 25px;
            font-size: 20px;
            font-weight: 400;
            color: var(--mainWhite);
          }
  
          div.MinusButton{
            position: absolute;
            margin-top: 10px;
            margin-left: 20px;
            left: 10px;
            color: white;
            line-height: 25px;
            font-size: 53px;
            font-weight: 300;
            color: var(--mainWhite);
          }
  
          div.MinusButton:hover{
            cursor: pointer;
          }
          div.PlusButton:hover{
            cursor: pointer;
          }
        }
      }
  
      div.DivMid {
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        flex: 3 3;
  
        div.ItemName {
          display: flex;
          word-wrap: break-word;
          padding-top: 5px;
          font-size: 22px;
        }
        
        div.ItemPrice {
          flex: 2 2;
          text-align: start;
          /* justify-content: center; */
          padding-top: 26px;
          font-size: 26px;
          font-weight: 600;
        }
        
        div.ItemUnits {
          /* align-self: center; */
          /* display: flex;
          gap: 10px; */
  
          div.Unit{
            width: 80px;
            height: 24px;
            font-size: 20px;
            border-radius: var(--brdRadius);
            border:1px solid var(--mainColor);
            margin-bottom: 5px;
            font-size: 17px;
            line-height: 23px;
          }
          div.Unit:hover{
            cursor: default;
          }
        }
      }
  
      div.DivRight {
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-content: center;
        flex: 0 0 240px;
        margin: 5px;
  
        img.ItemImg {
          max-width: 135px;
          height: 90px;
          margin: 0px;
        }
  
        div.ItemFlags {
          display: flex;
          text-align: center;
          justify-content: center;
        }
  
        div.iFlags {
          color: var(--mainWhite);
          width: 80%;
          max-width: 200px;
          border-radius: var(--brdRadius);
          height: 24px;
          font-size: 15px;
          font-weight: 500;
  
          &.iNew {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
  
          &.iSale {
            border:1px solid  #E10000;
            background-color: #E10000;
          }
  
          &.iSeason {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
        }
      }
    }
  
    div.DivNotInStock {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: var(--mainWhite);
      border-radius: var(--brdRadius);
      background-color: rgba(70, 70, 70, 0.8); 
      line-height: 140px;
      font-size: 60px;
      font-weight: 600;
    }
  }
}

/* @media screen and (max-width: 600px) {
    div.TopDiv {
      display: flex;
      flex-flow: row wrap;
    }
    div.ItemIcon {
      width: 50px;
      max-width: 50px;
      max-height: 50px;
      border:1px solid  var(--mainWhite);
      border-radius: 50%;
    }
    img.ItemImg {
      width: 50px;
      height: 50px;
      margin: 0px;
    }
  } */


  @media (max-width: 600px) {
    div.Container{
      background-color: #FFFFFF;
      border-radius: 10px;
      overflow: hidden;
      width: 40vw;
      height: 200px;
      margin-left: auto;
      margin-bottom: 10px;
      position: relative;
      /* overflow: visible; */

      div.TopDiv{

        div.DivLeft{
          display:flex;
          justify-content: center;

          div.DivLeftUp {
            /* border: 1px solid red; */
            position: absolute;
            top: 50%;
            left: 10px;
            color: var(--mainColor);
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            font-size: 8px;
    
            .DescOuterDiv {
              /* border: 1px solid red; */
              position: relative;
              /* height: 30px; */
              /* width: 100px; */
              text-align: left;
              cursor: help;
              width: 60%;
              margin-right: auto;
              

              div.label{
                /* border: 1px solid blue; */
                font-size: 11px;
              }

              .DescOuterPopup {
                position: fixed;
                left: -40px;
                z-index: 1;
    
                .DescArrow {
                  height: 15px;
                  width: 15px;
                  transform: rotate(45deg);
                  border: 1px solid var(--black);
                  position: absolute;
                  left: -7px;
                  top: 24px;
                  z-index: 1;
                }
    
                .DescArrow2 {
                  height: 13px;
                  width: 13px;
                  transform: rotate(45deg);
                  position: absolute;
                  left: -6px;
                  top: 25px;
                  z-index: 3;
                  background-color: var(--mainWhite);
                }
    
                .DescOuterPopupIn {
                  background-color: white;
                  min-width: 80px;



                  border: 1px solid var(--black);
                  display: inline-block;
                  padding: 10px;
                  padding-left: 15px;
                  padding-right: 15px;
                  background-color: var(--mainWhite);
                  text-align: center;
                  position: sticky;
                  z-index: 2;
                  color: var(--black);
                  border-radius: var(--brdRadius);
    
                  .DescPopupIcon {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    /* height: 30px; */
                    height: 100%;
                  }
    
                  .DescPopupText {
                    white-space: pre-wrap;
                  }
                }
              }
            }
          }
          
          div.DivLeftUp > * + *{
            margin-top: 3px;
          }

          div.DivLeftBottom {
            color: #FFFFFF;
            margin-top: 13px;
            text-align: center;
            display: flex;
            flex-flow: row nowrap;
            width: 89%;
            align-content: flex-end;
            justify-content: center;
            /* gap: 5px; */
            border-radius: 50px;
            border-width: 0px;
            height: max-content;
            position: relative;
          }
          div.DivLeftBottom > * + *{
            margin-right: 5px;
          }

        }

        div.DivMid{
          display: flex;
          flex-direction: column;

          div.ItemName{
            width: 100%;
            font-size: 13px;
            justify-content: center;
            max-height: 20px;
            padding: 0 5px 0 5px;
            
            div{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          div.ItemPrice{
            font-size: 13px;
            font-weight: 600;
            height: 20px;
          }

          div.ItemUnits {
            /* align-self: center; */
            /* display: flex;
            gap: 10px; */
            margin-right: 10px;
            margin-top: 10px;

            div.Unit{
              border: 1px solid red;
              /* width: 60px;
              height: 18px; */
              width: 45px;
              height: 16px;
              font-size: 13px;
              border-radius: var(--brdRadius);
              border:1px solid var(--mainColor);
              margin-bottom: 5px;
              line-height: 17px;
            }
          }
        }

        div.DivRight{
          /* border: 1px solid red; */
          display: flex;
          justify-content: center;

          position: relative;
          padding-top: 15px;

          img.ItemImg {
            width: 90px;
            height: 60px;
            /* margin: 0px; */
          }

          div.ItemFlags {
            width: 16px;
            height: 100%;
            
            position: absolute;
            top: 0;
            left: 6%;

            div.iFlags {
              color: var(--mainWhite);
              border-radius: 0 0 var(--brdRadius) var(--brdRadius);
              height: 80%;
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              overflow: hidden;
              
              &.iNew {
                padding-top: 80%;

                border:1px solid  #FF8000;
                background-color: #FF8000;
                
                div{

                  -webkit-transform: rotate(-90deg);
    
                  /* Firefox */
                  -moz-transform: rotate(-90deg);
                
                  /* IE */
                  -ms-transform: rotate(-90deg);
                
                  /* Opera */
                  -o-transform: rotate(-90deg);
                
                  /* Internet Explorer */
                  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                }
              }
      
              &.iSale {
                padding-top: 50%;

                border:1px solid  #E10000;
                background-color: #E10000;

                div{
                  -webkit-transform: rotate(-90deg);
    
                  /* Firefox */
                  -moz-transform: rotate(-90deg);
                
                  /* IE */
                  -ms-transform: rotate(-90deg);
                
                  /* Opera */
                  -o-transform: rotate(-90deg);
                
                  /* Internet Explorer */
                  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                }
              }
      
              &.iSeason {
                padding-top: 50%;

                border:1px solid  #FF8000;
                background-color: #FF8000;

                div{
                  -webkit-transform: rotate(-90deg);
    
                  /* Firefox */
                  -moz-transform: rotate(-90deg);
                
                  /* IE */
                  -ms-transform: rotate(-90deg);
                
                  /* Opera */
                  -o-transform: rotate(-90deg);
                
                  /* Internet Explorer */
                  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                }
              }
            }
          }
        }
      }

      div.DivNotInStock {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        color: var(--mainWhite);
        background-color: rgba(0, 0, 0, 0.68); 
        height: 100%;
        line-height: 50px;
        font-size: 40px;
        font-weight: 600;

        div{
          padding-top: 30%;
        }
      }
    }
  }