div.Container{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;
    
    div.OptionsDescription{
        font-size: 14px;
        margin-bottom: 5px;
    }

    div.ItemOptionList{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15% 0 15%;

        div.ItemOption{
            /* border: 4px solid pink; */
            border-bottom: 2px solid #D3D3D3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
            
            div.WrapCheckImg{
                display: flex;
                input{
                    cursor: pointer;
                }
    
                div.itemImage{
                    /* border: 1px solid blue; */
                    max-width: 6rem;
                }
            }
        }
    }

    div.ItemOptionList > * + *{
        margin-top: 10px;
    }
}



@media (max-resolution: 110dpi){
    div.Container{
        div.OptionsDescription{
            font-size: 18px;
        }
        
        div.ItemOptionList{
            div.ItemOption{
                font-weight: 500;
                font-size: 20px;
            }
        }
    }
}

@media (min-resolution: 130dpi){
    div.Container{
        div.OptionsDescription{
            font-size: 12px;
        }

        div.ItemOptionList{
            div.ItemOption{
                font-weight: 500;
                font-size: 12px;

                input{
                    width: 10px;
                }
            }
        }
    }
}

@media (min-resolution: 150dpi){
    div.Container{
        div.OptionsDescription{
            font-size: 10px;
        }

        div.ItemOptionList{
            div.ItemOption{
                font-weight: 500;
                font-size: 10px;
            }
        }
    }
}

@media (max-width: 850px){
    div.Container{
        div.OptionsDescription{
            font-size: 18px;
        }
        
        div.ItemOptionList{
            div.ItemOption{
                font-weight: 500;
                font-size: 17px;
            }
            div.ItemOption{
                div.WrapBtnAndName{
                    input{
                        width: 20px;
                    }
                }
            }
        }
    }
}