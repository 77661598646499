div.ModalContainer{
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div.Header{
        /* border: 1px solid blue; */
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }

    div.CartProducts{
        /* border: 1px solid green; */
        margin: auto;
        width: 90%;
        height: 80%;
        overflow: auto;
        
        display: flex;
        flex-wrap: wrap;

        div.ItemContainer{
            /* border: 4px solid red; */
            width: 10rem;
            height: 15rem;
            flex: 0 1 50%;
            cursor: pointer;

            div.ItemImage{
                /* border: 4px solid purple; */
                margin: auto;
                max-width: 50%;
                position: relative;

                div.PriceCircle{
                    /* border: 4px solid red; */
                    position: absolute;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-around;

                    top: 5%;
                    right: 5%;
                    /* max-width: 40%;
                    max-height: 40% ; */
                    width: 3.5rem;
                    height: 3.5rem ;
                    border-radius: 100%;
                    color: #FFF;
                    font-family: 'Heebo';
                    font-size: 100%;

                    padding:7%;
                    padding-bottom: 9%;

                    div.Agorot{
                        /* border: 4px solid red; */
                        /* position: absolute; */
                        /* font-size: 1.7vmin;
                        top: 28%;
                        right: 3%;  */
                        margin-bottom:30%;
                    }

                    div.Shekels{
                        /* border: 4px solid red; */
                        line-height: 100%;
                        /* position: absolute; */
                        /* width: 60%;
                        height: 60%; */
                        /* top: 20%;
                        right: 20%; */
                        text-align: left;
                        font-size: 2em;
                        /* overflow: auto; */
                        /* margin: 0 auto; */
                        
                    }
                    div.Curr{
                        /* border: 4px solid red; */
                        padding-top: auto;
                        /* position: absolute; */
                        /* top: 58%;
                        right: 80%; 
                        font-size: 1.7vmin; */
                    }
     
                }
            }

            div.ItemName{
                /* border: 1px solid red; */
                margin: auto;
                text-align: center;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.1rem;
                font-weight: 500;
            }
        }

        div.ItemContainer:hover{
            opacity: .8;
        }
    }

    div.NoThanksFooter{
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;

        span{
            /* border: 1px solid red; */
            font-size: 1.8rem;
            color: #7d7d7d;
            text-decoration: underline;
            cursor: pointer;
        }
        span:hover{
            opacity: .7;
        }
    }
}

@media (max-width: 600px){
    div.ModalContainer{
        div.Header{
            font-size: 1.5rem;
        }
    
        div.CartProducts{
           
            div.ItemContainer{

    
                div.ItemImage{
                    max-width: 75%;
    
                    div.PriceCircle{
                        
                        div.Agorot{
                        }
    
                        div.Shekels{
                        }
                        div.Curr{
                        }
         
                    }
                }
    
                div.ItemName{

                }
            }
        }
    
        div.NoThanksFooter{

            span{

            }
 
        }
    }
}